import React from 'react';
import { graphql } from 'gatsby';
import TitlePage from '../components/TitlePage';
import SEO from '../components/seo';
import Container from '../components/Container'
import RichText from '../components/RichText'
import useTranslations from '../components/useTranslations';
import useResponsiveImage from '../components/useResponsiveImage';

import * as S from './post.styled';



const Post = props => {
  const post = props.data.markdownRemark;
  const {
    by,
    backToNews
  } = useTranslations();  

  return (
    <Container>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />

      <S.BackLinkBold to={`/blog/`}>← {backToNews}</S.BackLinkBold>
      
      <S.PostWrapper>

          <S.PostImgWrapper>
          <S.PostImg
            fluid={useResponsiveImage(post.frontmatter.image)}
            alt={post.frontmatter.title}
          />
          </S.PostImgWrapper>


        <S.PostInfo>
          <S.PostDate>{post.frontmatter.date}</S.PostDate>
          <S.PostAuthor>{by} {post.frontmatter.author}</S.PostAuthor>
          <TitlePage text={post.frontmatter.title} />

          <S.PostImgContentWrapper>
            <S.PostImg
              fluid={useResponsiveImage(post.frontmatter.image)}
              alt={post.frontmatter.title}
            />
          </S.PostImgContentWrapper>

          <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
          <RichText dangerouslySetInnerHTML={{ __html: post.html }}/>

          <S.BackLinkBold to={`/blog/`}>← {backToNews}</S.BackLinkBold>

        </S.PostInfo>


      </S.PostWrapper>

    </Container>
  );
};

export const query = graphql`
  query Post($locale: String!, $dateFormat: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        date(formatString: $dateFormat, locale:$locale)
        author
        description
        image
      }
      html
    } 
  }
`;

export default Post;
